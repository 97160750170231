<template>
  <app-layout>
    <div class="px-4 p-sm-5 container">
      <div class="heading d-flex justify-content-between mb-5">
        <h2>Mon compte</h2>
      </div>

      <template v-if="isSaving">
        <b-spinner></b-spinner>
      </template>

      <div v-else>
        <div class="row">
          <div class="col-12" v-if="hasError">
            <div class="alert alert-danger">
              <div>Une erreur interne s'est produite. Réessayez dans quelques instants.</div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <credential-item
              title="Mot de passe"
              :fields="fieldPassword"
              :initial-values="{ password: '', confirmationPassword: '' }"
              display-value="••••••••"
              :validation-rules="validationRulesPassword"
              @edit="handleEditPassword"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <credential-item
              title="Adresse courriel"
              :fields="fieldEmail"
              :initial-values="{ email: user.email }"
              :display-value="user.email"
              :validation-rules="validationRulesEmail"
              @edit="handleEditEmail"
            />
          </div>
        </div>
      </div>
    </div>
  </app-layout>
</template>

<script>
import { pick } from 'lodash-es'
import CredentialItem from '@/components/account/CredentialItem'
import http from '@/utils/http'

export default {
  components: {
    CredentialItem
  },
  data () {
    return {
      isSaving: false,
      hasError: false
    }
  },
  computed: {
    user () {
      return this.$store.state.auth.user
    },
    fieldPassword () {
      return [
        {
          name: 'password',
          placeholder: 'Nouveau mot de passe',
          type: 'password',
          props () {
            return {
              autocomplete: 'new-password'
            }
          }
        },
        {
          name: 'confirmationPassword',
          placeholder: 'Confirmez le nouveau mot de passe',
          type: 'password',
          props () {
            return {
              autocomplete: 'new-password'
            }
          }
        }
      ]
    },
    validationRulesPassword () {
      return {
        password: 'required|min:6|max:42',
        confirmationPassword: 'required|min:6|max:42|same:password'
      }
    },
    fieldEmail () {
      return [
        {
          name: 'email',
          placeholder: 'E-mail'
        }
      ]
    },
    validationRulesEmail () {
      return {
        email: 'required|email'
      }
    }
  },
  methods: {
    async confirmPassword () {
      let password = null

      await this.$modal.openFormModal({
        title: 'Authentification',
        confirmLabel: 'Continuer',
        cancelLabel: 'Annuler',
        beforeContent: 'Pour des raisons de sécurité, veuillez entrer votre mot de passe actuel afin de compléter cette action.',
        fields: [
          {
            name: 'password',
            placeholder: 'Mot de passe actuel',
            type: 'password'
          }
        ],
        initialValues: {
          password: ''
        },
        onSubmit: async ({ values, confirm, setErrors }) => {
          setErrors({})

          try {
            await this.$store.dispatch('login', {
              username: this.user.email,
              password: values.password
            })
          } catch (e) {
            setErrors({ password: "Le mot de passe que vous avez entré n'est pas valide" })
            return
          }
          confirm()

          password = values.password
        }
      })

      return password
    },
    async handleEditPassword (data) {
      try {
        const payload = pick(data.form, ['password'])

        payload.username = this.$store.state.auth.user.email

        this.hasError = false

        await this.confirmPassword()

        this.isSaving = true

        await http.patch(`/v1/users/${this.user.id}/password`, payload)

        await this.$store.dispatch('login', payload)

        this.$toasted.show('Vos informations ont été mises à jour.', {
          position: 'top-center',
          type: 'success',
          duration: 3000
        })

        this.isSaving = false
      } catch (error) {
        this.isSaving = false
        this.hasError = error !== 'ACTION_CANCELLED'
      }
    },
    async handleEditEmail (data) {
      try {
        const payload = pick(data.form, ['email'])

        this.hasError = false

        const password = await this.confirmPassword()

        this.isSaving = true

        await http.patch(`/v1/users/${this.user.id}/email`, payload)

        await this.$store.dispatch('login', {
          username: payload.email,
          password
        })

        this.$toasted.show('Vos informations ont été mises à jour.', {
          position: 'top-center',
          type: 'success',
          duration: 3000
        })

        this.isSaving = false
      } catch (error) {
        this.isSaving = false
        this.hasError = error !== 'ACTION_CANCELLED'
      }
    }
  }
}
</script>
