<template>
  <div class="card shadow-sm mb-4 card-settings">
    <div class="card-body">
      <div class="card-title">
        {{ title }}
      </div>

      <div v-if="!isEditing" class="d-flex justify-content-between">
        <div class="value">
          {{ displayValue }}
        </div>
        <div class="edit">
          <a href="" @click.prevent="showHideForm">
            <small>Modifier</small>
          </a>
        </div>
      </div>

      <div v-if="isEditing" :class="{ 'flex-form' : Object.keys(fields).length === 2 }">
        <v-dynamic-form
          :fields="fields"
          :initial-values="initialValues"
          :errors="errors"
          @change="handleChange"
        />

        <div class="mt-3">
          <button class="btn btn-primary" @click="handleSubmit">
            Enregistrer
          </button>

          <button class="btn btn-link" @click="showHideForm">
            Annuler
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useValidator from '@/mixins/useValidator'

export default {
  mixins: [useValidator],
  props: {
    title: {
      type: String,
      required: true,
      default: ''
    },
    fields: {
      type: Array,
      required: true,
      default: () => {
        return []
      }
    },
    initialValues: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    },
    displayValue: {
      type: String,
      required: true,
      default: ''
    },
    validationRules: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    },
    dispatch: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      isEditing: false,
      form: {}
    }
  },
  methods: {
    handleChange (form) {
      this.form = form
    },
    handleSubmit () {
      this.errors = {}

      const isValidated = this.validate(this.form, this.validationRules)

      if (!isValidated) {
        return
      }

      this.$emit('edit', { form: this.form, dispatch: this.dispatch })
    },
    showHideForm () {
      if (this.isEditing) {
        this.errors = {}
        this.form = {}
      }

      this.isEditing = !this.isEditing
    }
  }
}
</script>

<style lang="scss">
.card-settings {
  .value {
    font-weight: 500;
  }

  .custom-input .form-control {
    border: 1px solid #666;
    width: 275px;
    height: 25px;
  }

  .flex-form {
    .dynamic-form {
      display: flex;

      .form-group {
        width: 50%;

        &:not(:first-child) {
          padding-left: 10px;
        }
      }
    }
  }
}
</style>
